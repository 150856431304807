/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import { styled, ThemeProvider, withTheme } from '@smooth-ui/core-sc';

import SvgIcon from '../../components/SvgIcon/SvgIcon';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
`;

const LoginContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.25rem 2rem;
  max-width: 600px;
  min-width: 300px;
  padding: 1rem;
  width: 50%;
`;

const LoginLogo = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  text-align: center;
  img {
    height: 72px;
    height: auto;
    width: 192px;
  }
`;

const LoginPage = ({ theme }) => (
  <PublicComponent redirectOnError="/">
    <PageContainer>
      <ThemeProvider
        theme={{
          customHeaderBackground: 'transparent',
          // buttonBlockHasSeparator: true,
        }}
      >
        <LoginContainer>
          <LoginLogo>
            <SvgIcon
              icon="footer-logo"
              scaleWidth="64"
              scaleHeight="24"
              width="192px"
              height="72px"
              fill="#9B9C9B"
              title="Hewlett Packard Platinum Club"
            />
          </LoginLogo>

          <LoginProvider disableMeta>
            <LoginForm formLabels={{ labelUserName: 'Username' }} />
          </LoginProvider>
        </LoginContainer>
      </ThemeProvider>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(LoginPage);
